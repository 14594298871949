import dynamic from "next/dynamic"
import cn from "classnames"
import richText from "utilities/richText"
import useHasIntersected from "utilities/useHasIntersected"

export default function ImageAndContent({ blok }) {
  const Images =
    blok.images.length < 3
      ? dynamic(() => import("components/SingleImage"))
      : dynamic(() => import("components/ImageCollage"))

  const { ref, isIntersecting } = useHasIntersected()

  function Decoration({ decoration }) {
    if (!decoration || decoration === "") return null

    let Decor = () => null,
      styles = ""

    switch (decoration) {
      case "":
        break
      case "lines":
        Decor = dynamic(() => import("public/assets/lines.svg"))
        styles = `${isIntersecting ? "lines " : ""}-top-48 sm:-top-12 right-0 sm:right-auto scale-110`
        break
      case "dots":
        Decor = dynamic(() => import("public/assets/dots.svg"))
        styles = `${
          isIntersecting ? "fade-in " : ""
        }-top-20 right-1/2 translate-x-1/2 scale-90 lg:right-auto lg:translate-x-0 lg:scale-100 sm:scale-110`
        break
      case "brush":
        Decor = dynamic(() => import("public/assets/brush.svg"))
        styles = `${isIntersecting ? "fade-in " : ""}-top-28 scale-90 -left-16 sm:scale-100 sm:-left-28`
        break
      default:
        break
    }

    return (
      <div ref={ref}>
        <Decor className={cn("absolute z-0", styles)} />
      </div>
    )
  }

  const container = cn("image-content-container flex flex-col gap-4 lg:gap-0 py-12 mx-auto max-w-screen-xl", {
    "lg:flex-row": blok.orientation === "content_first",
    "lg:flex-row-reverse": blok.orientation === "image_first",
  })

  const content = cn(
    "image-content relative z-50 px-6 sm:px-0 basis-1/2 self-center z-10 prose prose-h2:pb-4 prose-p:py-2",
    {
      "lg:pr-12": blok.orientation === "content_first",
      "lg:pl-12": blok.orientation === "image_first",
    },
  )

  return (
    <section
      className={cn("sm:px-6", blok.background_color && `bg-${blok.background_color}`)}
      id={blok?.id ? blok?.id : null}
    >
      <div className={container}>
        <div className={content}>{richText(blok.content)}</div>
        <div className="image-decor relative z-10 basis-1/2 lg:max-h-[500px] lg:min-h-96">
          {!blok?.decoration || blok?.decoration !== "" ? <Decoration decoration={blok?.decoration} /> : null}
          <div className={isIntersecting && blok?.decoration ? `image-fade-in-${blok.decoration}` : ""}>
            <Images {...blok} />
          </div>
        </div>
      </div>
    </section>
  )
}
